<template>
  <div class="view-men">
    <h3>一、用户注册</h3>
    <p class="tex-indent">1，如何注册？</p>
    <p class="tex-indent-o">
      在焱十一首页，点击“注册”按钮，填上您的常用手机号码，点击“获取验证码”按钮，填上您手机收到的验证码，阅读并同意《焱十一隐私政策》和《焱十一用户注册协议》，点击“注册”按钮即可完成用户注册。</p>
    <p class="tex-indent">2，如何登录？</p>
    <p class="tex-indent-o">
      在焱十一首页，点击“登录”按钮，填上您注册的手机号码，点击“获取验证码”按钮，填上您手机收到的验证码，阅读并同意《焱十一隐私政策》和《焱十一用户注册协议》，点击“登录”按钮即可登录该用户在焱十一的帐户。</p>
    <p class="tex-indent">3，如何成为竞买人？</p>
    <p class="tex-indent-o">
      第一步：登录焱十一用户帐号。</p>
    <p class="tex-indent-o">
      第二步：进入“用户中心”的“实名认证”，完成实名认证。</p>
    <p class="tex-indent-o">
      只有完成实名认证的用户方可成为竞买人。</p>
    <p class="tex-indent">4，如何完善和修改个人资料？</p>
    <p class="tex-indent-o">
      登录焱十一的用户中心，可完善和修改“收货地址”和“个人资料”。</p>
    <h3>二、关于拍卖会</h3>
    <p class="tex-indent">1，如何查看拍卖会？</p>
    <p class="tex-indent">
      拍卖会分两种形式，分别为直播竞拍和委托代拍。查看拍卖会的途径有4种：
    </p>
    <p class="tex-indent-o">
      第1种，从焱十一首页的“拍卖会”栏目点击进入所有的拍卖会列表页，可分类查看不同国家和地区、不同品类和不同拍卖行的拍卖会。
    </p>
    <p class="tex-indent-o">
      第2种，从焱十一首页的“分类”栏目点击进入查找各分类下的拍卖会。
    </p>
    <p class="tex-indent-o">
      第3种，从焱十一首页的“拍卖行”栏目点击进入查找各拍卖行的拍卖会。
    </p>
    <p class="tex-indent-o">
      第4种，从焱十一拍品详情页面点击进入该拍品关联的拍卖会。
    </p>
    <p class="tex-indent">
      2，如何查看拍卖行信息？
    </p>
    <p class="tex-indent-o">
      点击拍卖行名称即可进入拍卖行详情页，查询该拍卖行的简介、已结束拍卖会和即将开拍的拍卖会。
    </p>
    <p class="tex-indent">
      3，如何收藏拍卖会？
    </p>
    <p class="tex-indent-o">
      拍卖会名称后面有个爱心图案，点击爱心，即可收藏您关注的拍卖会。
    </p>
    <p class="tex-indent">
      4，如何收藏拍卖行？
    </p>
    <p class="tex-indent-o">
      拍卖行名称后面有个爱心图案，点击爱心，即可收藏您关注的拍卖行。
    </p>
    <p class="tex-indent">
      5，如何收藏拍品？
    </p>
    <p class="tex-indent-o">
      拍品名称后面有个爱心图案，点击爱心，即可收藏您关注的拍品。
    </p>
    <p class="tex-indent">
      6，拍品的来源是什么？
    </p>
    <p class="tex-indent-o">
      拍品由拍卖公司提供。特别需要说明的是，拍品有可能使用过、维修过，产品外观和性能可能有瑕疵、缺陷、缺失等状况，具体情况请参照拍品详情页面说明，并以您收到的实物为准。
    </p>
    <p class="tex-indent">
      7，如何设置拍卖会的开拍提醒？
    </p>
    <p class="tex-indent-o">
      拍卖会名称后面有个铃铛图案，点击铃铛，即可设置该场次拍卖会的开拍提醒。
    </p>
    <h3>三、关于竞拍费用</h3>
    <p class="tex-indent">
      1，什么是落槌价？
    </p>
    <p class="tex-indent-o">
      指在已结束的拍卖会中出价最高，且不低于拍卖公司规定的最低竞价或保留价，并且被本场拍卖会的拍卖师确认为成功竞得的出价。
    </p>
    <p class="tex-indent">
      2，什么是买家佣金？
    </p>
    <p class="tex-indent-o">
      买家佣金是指拍卖行按拍卖落槌价的一定比例向买受人收取的服务费。
    </p>
    <p class="tex-indent-o">
      买受人在竞拍成功后，需根据拍品详情页中关于买家佣金的收取约定，缴纳约定比例的买家佣金。
    </p>
    <p class="tex-indent-o">
      买受人竞拍成功后，系统会根据拍品落槌价和约定的佣金比例计算买家佣金，在落槌价的基础上叠加买家佣金后即为竞拍成功拍品的总成交价。
    </p>
    <p class="tex-indent">
      3，什么是平台服务费？
    </p>
    <p class="tex-indent-o">
      平台向用户提供在线竞拍的信息和技术服务所收取的服务费和软件使用费用。
    </p>
    <p class="tex-indent">
      4，什么是税费和其他费用？
    </p>
    <p class="tex-indent-o">
      根据拍卖行所在国家的政策不同，可能会产生所在国的税费。或根据买受人的服务需求，拍卖行可能会收取相应的其他费用。
    </p>
    <p class="tex-indent">
      5，什么是竞价阶梯？
    </p>
    <p class="tex-indent-o">
      竞价阶梯是指拍卖拍行在拍卖前公布的拍品竞价增涨金额幅度。
    </p>
    <h3>四、关于委托代拍</h3>
    <p class="tex-indent">
      1，什么是委托代拍？
    </p>
    <p class="tex-indent-o">
      对于没有进行网络直播的拍卖会，拍卖行通过焱十一在网络上展示拍卖标的，竞买人可以通过焱十一委托出价的方式委托焱十一代拍。
    </p>
    <p class="tex-indent">
      2，委托出价、加价和撤销出价
    </p>
    <p class="tex-indent-o">
      在委托代拍场次，竞买人在系统规定的委托出价截止时间内，可以出价、增加出价或撤销出价。当委托出价时间截止，竞买人不能再加价或撤销。
    </p>
    <p class="tex-indent">
      3，竞拍过程中的领先和被超越
    </p>
    <p class="tex-indent-o">
      竞拍过程中，您的出价可能暂时处于领先状态，亦可能被超越。处于领先状态并不代表您已竞得该拍品，竞拍成功与否与以拍卖行的拍卖结果为准。被超越并不代表您未竞得，您在委托出价时间截止之前仍然可以增加出价以获得更大的竞得机会。
    </p>
    <h3>五、关于直播竞拍</h3>
    <p class="tex-indent">
      1，什么是直播竞拍？
    </p>
    <p class="tex-indent-o">
      拍卖行通过焱十一直播拍卖会，使竞买人得以通过焱十一参与网上直播的拍卖会并以网络实时出价或网络预约出价的方式参与竞拍。
    </p>
    <p class="tex-indent">
      2，预约出价的出价、加价和撤销出价
    </p>
    <p class="tex-indent-o">
      在直播竞拍场次，竞买人可在拟竞拍的拍卖标的开拍前，在该拍卖标的的拍品详情页面中进行预约出价，待该拍卖标的开始直播竞拍起，竞买人不得撤销该出价，但竞买人可继续在直播控制器中进行加价。加价幅度应与该场次规定的竞价阶梯一致，不得随意加价。
    </p>
    <p class="tex-indent">
      3，直播中的实时出价
    </p>
    <p class="tex-indent-o">
      在直播竞拍场次，竞买人可在拟竞拍的拍卖标的正在直播竞拍时，在直播控制器中进行实时出价，实时出价不得撤销。加价幅度应与该场次规定的竞价阶梯一致，不得随意加价。
    </p>
    <h3>六、关于保证金</h3>
    <p class="tex-indent">
      1，保证金规则
    </p>
    <p class="tex-indent-o">
      1.1，拍卖公司在焱十一开展的竞拍活动要求竞买人交纳相应保证金，竞买人按照拍卖公司的要求支付了足额的保证金后方可参与竞拍。
    </p>
    <p class="tex-indent-o">
      1.2，保证金比例：保证金比例由拍卖公司规定，并在每场拍卖会的页面公布保证金比例。根据竞买人拟出价的金额，竞买人需要按照保证金比例交纳相应金额的保证金。例如，保证金比例为1／4（保证金／出价金额）时，竞买人若想出价人民币400元，则需要交纳人民币100元的保证金；若竞买人想出价人民币10000元，则需交纳人民币2500元的保证金；……以此类推，目前出价金额暂不设上限。保证金交纳成功即视为出价成功。保证金金额按四舍五入取整数计算。
    </p>
    <p class="tex-indent">
      2，保证金的冻结、解冻和扣除
    </p>
    <p class="tex-indent-o">
      2.1，直播竞拍场次的『预约出价』环节，竞买人的出价需按保证金比例交纳相应的保证金。当竞买人加价时，需要按保证金比例追加保证金。保证金将被冻结在竞买人『钱包』的『保证金』账户里。若竞买人未竞得该件拍卖标的，竞买人针对该拍卖标的在『预约出价』环节中交纳的保证金（包括在『预约出价』环节追加的保证金）将在该场拍卖会结束后解冻，无息转入竞买人『钱包』的『可用余额』中。如竞买人成功竞得该件拍卖标的，则竞买人针对该拍卖标的在『预约出价』环节中交纳的保证金（包括在『预约出价』环节追加的保证金）将在该件拍卖标的的订单结清后解冻。
    </p>
    <p class="tex-indent-o">
      2.2，直播竞拍场次的『实时出价』环节，竞买人应保证您『钱包』内的『可用余额』充足，避免因余额不足而影响竞拍过程。当竞买人进行『实时出价』时，系统会根据竞买人的出价金额和该场次的保证金比例，计算竞买人应交纳的保证金金额，并将该笔保证金从竞买人『钱包』的『可用余额』转入您『钱包』的『保证金』中进行冻结。如竞买人加价，则系统仍根据竞买人的加价金额计算应追加的保证金金额并转入竞买人『钱包』的『保证金』中进行冻结。当竞买人拟竞拍的拍卖标的拍卖结束后，如竞买人未竞得该件拍卖标的，则竞买人针对该拍卖标的在『实时出价』环节中交纳的保证金（包括在『实时出价』环节追加的保证金）将在该件拍卖标的结束后实时解冻，并无息转入竞买人『钱包』的『可用余额』内。如竞买人成功竞得该件拍卖标的，则竞买人针对该拍卖标的在『实时出价』环节交纳的保证金（包括在『实时出价』环节追加的保证金）将在该件拍卖标的的订单结清后解冻。
    </p>
    <p class="tex-indent-o">2.3，在直播竞拍场次中，针对一件拍卖标的，如竞买人既有『预约出价』时交纳的保证金，又有在『实时出价』时交纳的保证金，则保证金的解冻时间按上述约定根据交纳环节的不同而分别处理。</p>
    <p class="tex-indent-o">
      2.4，在委托代拍场次，竞买人在系统规定的『委托出价』截止时间内，可以增加或撤销出价。当『委托出价』时间截止，竞买人不能再加价或撤销。竞买人根据保证金比例交纳的保证金金额将在竞买人『钱包』的『保证金』账户内冻结，直至该场拍卖会结束。如竞买人未竞得该件拍卖标的，则系统会将您针对该拍卖标的而交纳的保证金无息转入竞买人『钱包』的『可用余额』内。如竞买人成功竞得该件拍卖标的，则竞买人针对该拍卖标的而交纳的保证金将在该件拍卖标的的订单结清后解冻。
    </p>
    <p class="tex-indent-o">2.5，凡在竞买人『钱包』的『保证金』账户内的资金，均是被冻结的状态。保证金解冻是指保证金从竞买人『钱包』的『保证金』账户无息转入竞买人『钱包』的『可用余额』里。</p>
    <p class="tex-indent-o">
      2.6，如买受人未在规定期限内足额支付拍品款，买受人需要按拍卖公司的规定承担违约责任。我们将按当时买受人参加该件拍卖标的竞拍时交纳的保证金比例，将买受人的保证金作为违约金进行扣除，例如，买受人参加该件拍卖标的竞拍时按１／４交纳的保证金，则我们扣除的保证金金额为该件拍卖标的落槌价的１／４，按四舍五入取整数计算。如被扣除的保证金不足违约金数额的，买受人应当补齐违约金。此外，我们及拍卖公司还有权视具体情况依照《拍卖规则》及相关法律的规定处分该拍卖标的，包括但不限于由拍卖公司对此件拍卖标的重新拍卖或退还给拍卖委托人。我们无需就代为扣除的保证金向买受人开具发票及收据等相关凭证。
    </p>
    <h3>七、关于拍卖结果</h3>
    <p class="tex-indent">1，如何查询我的竞拍记录？</p>
    <p class="tex-indent-o">在焱十一的首页，点击进入“我的”-“竞拍记录”，即可查看竞价中、待确认、已竞得、未竞得的竞拍记录。</p>
    <p class="tex-indent">2，如何查看竞得订单？</p>
    <p class="tex-indent-o">在焱十一的首页，点击进入“我的”-“我的订单”，即可查看我的订单。</p>
    <h3>八、资金账户</h3>
    <p class="tex-indent">1，钱包</p>
    <p class="tex-indent-o">用户可将自有资金转入到其焱十一帐号的『钱包』里，该笔资金不论是在『可用余额』内还是在『保证金』内，均不产生任何利息或收益，且用户知悉并同意自行完全承担货币贬值（汇率变化）等风险。
    </p>
    <p class="tex-indent">2，如何充值？</p>
    <p class="tex-indent-o">用户可通过银行卡、微信、支付宝等方式对钱包进行充值。
    </p>
    <p class="tex-indent">3，如何提现？</p>
    <p class="tex-indent-o">
      如果用户没有需要支付的拍品款，用户可以选择将『可用余额』内的资金进行提现，因“提现”而产生的手续费由您自行承担，手续费费率按各支付渠道标准执行。『提现』的资金将根据用户之前的支付路径原路转回。
    </p>
    <h3>九、其他常见问题</h3>
    <p class="tex-indent">1，已经竞拍成功，但是不想要这个拍品了怎么办？</p>
    <p class="tex-indent-o">
      如买受人未在规定期限内足额支付拍品款，买受人需要按拍卖公司的规定承担违约责任。我们将按当时买受人参加该件拍卖标的竞拍时交纳的保证金比例，将买受人的保证金作为违约金进行扣除，例如，买受人参加该件拍卖标的竞拍时按１／４交纳的保证金，则我们扣除的保证金金额为该件拍卖标的落槌价的１／４，按四舍五入取整数计算。如被扣除的保证金不足违约金数额的，买受人应当补齐违约金。此外，我们及拍卖公司还有权视具体情况依照《拍卖规则》及相关法律的规定处分该拍卖标的，包括但不限于由拍卖公司对此件拍卖标的重新拍卖或退还给拍卖委托人。我们无需就代为扣除的保证金向买受人开具发票及收据等相关凭证。
    </p>
    <p class="tex-indent">2，拍品支持七天无理由退货吗？
    </p>
    <p class="tex-indent-o">由于拍卖品的特殊性，根据焱十一服务规则、以及各拍卖行的竞拍条款，一经拍出的拍品不支持七天无理由退货。
    </p>
    <p class="tex-indent">3，竞拍过程中我能任意幅度加价吗？</p>
    <p class="tex-indent-o">不能，每个拍卖行会有设定好的竞价阶梯，竞买人需按照竞价阶梯的幅度出价。
    </p>
    <p class="tex-indent">4，未实地查看拍品的风险</p>
    <p class="tex-indent-o">
      实地查看拍卖标的是您的法定权利。如果您在拍卖标的展示的时间内未查看拍卖标的，视为您自愿放弃这一权利，愿意以拍卖标的的现状参与竞拍。您充分了解并自愿承担未实地查看拍卖标的的风险。因您未实地查看拍标的而产生的法律风险由您自行承担，您不得再以拍卖标的存在实物瑕疵为由提出异议。
    </p>
    <p class="tex-indent">5，关于拍品的真伪</p>
    <p class="tex-indent-o">
      焱十一及拍卖公司特别声明不能保证拍卖标的的真伪、品质及价值，对拍卖标的不承担瑕疵担保责任。在焱十一展示的拍卖会信息及拍卖标的资料（包括但不限于文字、图片、视频、来源、鉴定结果、评价等）、拍卖结果信息等均由拍卖公司自行提供，您应自行判断拍卖标的的优劣、真伪等，并对竞拍行为负责。焱十一不参与对任何拍卖标的的描述、评估、鉴定等，亦不对拍卖公司的任何口头、书面陈述或上传的线上信息及拍卖标的的真实性、合法性、准确性等做任何明示或暗示的担保，或对此承担任何责任。
    </p>
    <p class="tex-indent">6，竞拍结束时，竞拍人的出价最高，处于领先，为什么还显示流拍或未竞得？</p>
    <p class="tex-indent-o">
      由于您出价的这个拍品有设置保留价，在竞拍结束时，如最高出价记录没有达到该拍品设置的保留价，即会显示流拍。或现场有其他买家的出价超越了您的网上出价，所以即使您的出价在网络上显示处于领先，但是您仍然未竞得。
    </p>
    <p class="tex-indent">7，如何领取竞得拍品？</p>
    <p class="tex-indent-o">
      通过焱十一成功竞得的拍卖标的的交割，包括拍卖标的的包装、运输、领取等一切事项，均在买受人和拍卖公司及包装运输服务提供方之间进行，我们不介入任何拍卖标的的交割事务。我们对拍卖公司或买受人选用的包装及运输服务提供方所造成的一切错误、遗漏、损坏或灭失等，不承担任何责任。如因因海关和/或检验检疫部门的规定以及国家相关的法律、法规、政策等原因造成竞得的拍卖标的无法顺利入境的，焱十一不承担任何责任。
    </p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      centerDialogVisible: false
    }
  },
  methods: {
    book () {
      this.centerDialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.view-men {
  text-align: left;
  padding: 20px 80px;
  p,
  h3 {
    font-size: 15px;
  }

  .tex-indent {
    text-indent: 2em;
  }
  .tex-indent-o {
    text-indent: 4em;
  }
  .book {
    color: #333;
    text-decoration: underline;
  }
}
</style>
